/*------------------- image side ------------------------ */
.registerPage {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    background-image: url("../../assets/images/bg-login.png");
    background-size: cover;
}


/*------------------- form side ------------------------ */
.registerFormContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border: 2px solid #0a143f4b;
    background-color: #050A27;
    border-radius: 3px;
}

.formContainer h2 {
    color: white;
}

.formContainer h2 span {
    color: #247dc2
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
}

.inputGrp {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputGrp label {
    font-weight: 600;
}

.spanCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -5px;
}

.input-text {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    color: black;
    border: 2px solid  #4c7ea373;
    padding-left: 10px;
    font-weight: 500;
}

.input-text:focus {
    border-color: #0766AD;
    outline: none;
}
.input-checkbox {
   width: 15px !important;
}

.checkboxCont {
    display: flex;
    gap: 7px;
    font-weight: 500;
}

.loginButton {
    width: 100%;
    height: 43px;
    border: none;
    background-color: rgba(5, 5, 218, 0.703);
    color: white;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px 0;
    transition: 0.5s;
}
.previousButton {
    width: 100%;
    height: 43px;
    border: none;
    background-color: #ca8a04  ;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px 0;
    transition: 0.5s;
}
.form-button {
    width: 100%;
    height: 43px;
    border-width:  0.01em;
    border-style:solid;
    background-color: transparent;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px 0;
    transition: 0.5s;
}

.loginButton:hover {
    background-color: white;
    color: black;
    border: none;
    transition: 0.5s;
}
.previousButton:hover {
    background-color: white;
    color: black;
    border: none;
    transition: 0.5s;
}


.form-button:hover {
    background-color: white;
    color: black;
    border: none;
    transition: 0.5s;
}

a {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

label {
    font-size: 15px;
    color: white;
    font-weight: 500;
}

.title-input {
    font-size: 20px;
    color: white;
    font-weight: 500;
    text-align: center;
}

.registerCont {
    display: flex;
    justify-content: space-between;
}

.registerCont p {
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1500px) {

    .registerFormContainer {
        width: 95vw;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .login-logo{
        width: 290px;
    }
}
