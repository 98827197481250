.body-cont{
    display: flex;
    gap: 300px;
}

.charts{
    width: 80%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr  ;
    grid-template-rows: 2fr 2fr ;
    gap: 50px;
    position: relative;
    
   
}

.overflow-y-auto::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  
  .overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
    border: 2px solid #f5f5f5;
  }
  
  .overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8;
  }