.node__root > circle {
    fill: #050a27;
  }
  
  .node__branch > circle {
    fill: #050a27;
  }
  
  .node__leaf > circle {
    fill: #050a27;
    /* Let's also make the radius of leaf nodes larger */
    
  }