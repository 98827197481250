.demandsList{
    padding-left: 300px ;
    padding-right: 60px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.add-button{
    margin-bottom: 20px;
}

.butonCl{
    float: right;
}

/* //////////////////////////////////////// */


.demandDetailCont{
    width: 87vw;
    padding-right: 350px;
    padding-left: 350px;
    padding-top: 35px;
    color: gray;
    min-height: 100vh;

}

.demandPage{
    width: 100%;
    background-color: rgb(246, 246, 246);
    padding: 0px 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 100px;
    padding-top: 30px;
}

.page-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25%;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
}

.page-top-info{
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 10px;
    gap: 10px;
}

.page-top-info h1{
    font-size: 24px; 
    font-weight: 600;
    margin-bottom: 20px;
    color: rgb(95, 95, 95);
}

.page-top-info p span{
    font-weight: bold;
}
.page-top-logo{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 60%;
}

.page-mid-info{
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-bottom: 1px solid;
}

.page-mid-info h1{
    font-weight: 600;
}

.page-mid-items{
    border-bottom: 1px solid;
    height: 30%;
    padding-bottom: 25px;
}

.page-bottom{
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    gap: 20px;
}

.total-detail{
    font-size: 20px;
}

.total-detail h1 span{
    font-weight: 600;
}

.resultMoney{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
    margin-bottom: 20px;
}

.resultMoney h1{
    font-weight: 500;
}

.resultMoneyInfo{
    display: flex;
    gap: 20px;
}