@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

input:focus {
  border-color: #034475 !important;
  outline: none;
  border-width: 2px;
}

input {
  width: 100%;
  border-radius: 3px !important;
  color: black;
  border: 2px solid #4c7ea373;
  padding-left: 10px;
  font-weight: 500;
  background-color: white !important;
}

input[type="checkbox"] {
  /* Buraya istediğin stilleri ekle */
  width: 25px;
}

textarea {
  width: 100%;
  border-radius: 3px !important;
  color: black;
  border: 2px solid #4c7ea373;
  padding-left: 10px;
  font-weight: 500;
  background-color: white !important;
}

.special-input {
  background-color: white !important; /* Varsayılan olarak beyaz */
  width: 100%;
  border-radius: 3px !important;
  color: black;
  border: 2px solid #4c7ea373;
  padding-left: 10px;
  font-weight: 500;
}

.special-input.red-bg {
  background-color: #fa7c7c  !important;
  width: 100%;
  border-radius: 3px !important;
  color: white;
  border: 2px solid red;
  padding-left: 10px;
  font-weight: 500;
}

.special-input.green-bg {
  background-color: #80f0ab !important;
  width: 100%;
  border-radius: 3px !important;
  color: black;
  border: 2px solid green;
  padding-left: 10px;
  font-weight: 500;
}

/* REACT-SELECT BOX */
.custom-select__control {
  min-height: 42px !important; /* Örneğin 20px yerine 40px yapabilirsiniz */
}
.custom-select__single-value {
  padding-left: 4px; /* Sol padding kaldırılır */
}

.custom-select__multi-value {
  display: inline-flex !important; /* Yan yana sıralamak için */
  margin-right: 4px !important; /* Öğeler arasına boşluk eklemek için */
}

/* Daha spesifik bir seçimle */
.custom-select__control.custom-select__control {
  border-radius: 3px !important;
}
.custom-select__placeholder {
  padding-left: 5px;
}

/* ///////////////////////////////////// */

.leaflet-container {
  width: 100%;
  height: 20vh;
}

.leaflet-container .leaflet-control-attribution {
  display: none;
}

input::file-selector-button {
  font-weight: bold;
  color: white;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  background-color: rgb(0, 0, 41);
}

.title {
  color: #050a27;
}
.subtitle {
  color: #328fb1;
}

.datepicker-zindex {
  z-index: 9999 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
